import React, { Fragment } from "react";
import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { HPImage, HPImageWrapper, GradientBackgroundExtender, GradientBackgroundImage, GradientContent } from "../../style/Hero";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Button } from "../../style/Button";
import Link from "../Link";
import { Wysiwyg } from "../../style/Wysiwyg";
import Breadcrumb from "../Breadcrumb";
import { Image } from "../../style/Image";
import TagLink from "../TagLink";
import Icon from "../../style/Icon";
import { Link as FakeLink } from "../../style/Link";
import { URLS } from "../../const/const";

const Hero = props => {
  const {
    heroBg,
    breadcrumbs,
    content: { title, titletop, cta, content, date, uri },
    type,
    categories,
    image,
  } = props;
  return (
    <Fragment>
      {type === "gradient" && (
        <Wrapper position="relative" overflow="hidden">
          <GradientBackgroundExtender side="left" bg={heroBg} />

          <Container display="block" zIndex="500" position="relative" flexDirection={["column", null, "row"]}>
            <GradientContent heroBg={heroBg} flexDirection="column" alignItems="flex-start">
              {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} mb={2} />}

              {titletop && (
                <Text as="h3" fontSize={2} lineHeight={1} color="headlines">
                  {titletop}
                </Text>
              )}
              {title && (
                <Heading
                  as={"h1"}
                  variant={"h1"}
                  pr={["0", null, "40%", "50%", null]}
                  dangerouslySetInnerHTML={{ __html: title }}
                  uppercase={!categories && true}
                  mb={[2, null, 4]}
                />
              )}
              {content && <Wysiwyg dangerouslySetInnerHTML={{ __html: content }} mb={4} pr={["0", null, "40%", "50%", "60%"]} />}
              {date && <Wysiwyg dangerouslySetInnerHTML={{ __html: date }} color={"headlinesSecondary"} mb={4} pr={["0", null, "40%", "50%", "60%"]} />}
              {cta && (
                <Button as={Link} href={cta.url} target={cta.target} display="inline-block" mb={[48, null, 0]}>
                  {cta.title}
                </Button>
              )}
              {categories && (
                <Flex paddingRight={["0", null, "40%", "50%", "60%"]} alignItems="center" flexWrap="wrap" marginTop="auto" mb={4}>
                  {categories.map(category => (
                    <TagLink key={category.id} href={`${URLS.blog}${category.slug}`} content={category.name} variant={"white"} icon={"dolar-icon"} />
                  ))}
                </Flex>
              )}
            </GradientContent>
            <Box display={["block", null, "none"]} mx={"-15px"}>
              <Image boxShadow={"one"} width="100%" src={image} />
            </Box>
            <GradientBackgroundImage heroBg={heroBg} backgroundImage={`url(${image})`} />
          </Container>

          <GradientBackgroundExtender side="right" bg={heroBg} />
        </Wrapper>
      )}
      {type === "magazine" && (
        <Wrapper bg={"panelSecondary"} mb={[4, null, "92px"]}>
          <Container>
            <Flex width={"100%"} flexDirection={["column", null, "row"]} justifyContent={"space-between"}>
              <Flex
                flexDirection={"column"}
                alignItems={"flex-start"}
                pt={[0, null, "55px"]}
                pr={[0, null, "15%", "9%", 32]}
                maxWidth={["none", null, 590]}
                order={[1, null, 0, null, null]}
              >
                {title && (
                  <Link href={`/magazin${uri}`}>
                    <Heading
                      as="h2"
                      color={["headlines", null, "link"]}
                      lineHeight={1}
                      uppercase={false}
                      mt={[4, null, 0]}
                      mb={2}
                      fontSize={[4, null, null, 5]}
                      fontWeight={300}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </Link>
                )}
                {date && <Wysiwyg color={"headlinesSecondary"} mb={3} dangerouslySetInnerHTML={{ __html: date }} />}
                {categories && (
                  <Flex alignItems="center" flexWrap="wrap" mb={3}>
                    {categories.map(category => (
                      <TagLink key={category.id} href={`/magazin/${category.slug}`} content={category.name} variant={"white"} icon={"dolar-icon"} />
                    ))}
                  </Flex>
                )}
                {content && <Wysiwyg color={"textGrey"} mb={3} lineHeight={"24px"} dangerouslySetInnerHTML={{ __html: content }} />}
                <FakeLink variant="smallBorderTop" mt={"auto"} mb={3} as={Link} href={`/magazin${uri}`}>
                  celý článek
                  <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
                </FakeLink>
              </Flex>
              <HPImageWrapper>
                <HPImage
                  width={["100%", null, null, null, "590px"]}
                  height={["260px", "300px", "auto", null, "394px"]}
                  image={image?.heroImage ?? image?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                >
                  <Image
                    src={image?.heroImage ?? image?.sourceUrl ?? require("../../assets/images/placeholder/test-image-9.jpg").default}
                    width={["100%", null, null, null, "590px"]}
                    height={["260px", "300px", "auto", null, "394px"]}
                    opacity="0"
                  />
                </HPImage>
              </HPImageWrapper>
            </Flex>
          </Container>
        </Wrapper>
      )}
    </Fragment>
  );
};

export default Hero;
