import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import moment from "moment";

import TagLink from "../components/TagLink";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Hero from "../components/Hero";
import MagazineBlockSmall from "../components/MagazineBlock/MagazineBlockSmall";
import MagazineBlockBig from "../components/MagazineBlock/MagazineBlockBig";

import { Container, Flex, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";

import { URLS } from "../const/const";

const Magazin = ({
  data: {
    wp: {
      posts: { nodes: posts },
      stickyPosts: { nodes: stickyPosts },
      categories: { nodes: categories },
    },
  },
  uri,
  pageContext: { hasNextPage, pageNumber },
}) => {
  require("moment/locale/cs");

  const breadcrumbs = [
    {
      label: "Magazín",
      link: uri,
    },
  ];

  let heroPost;
  let bannerCareer;

  if (pageNumber === 0) {
    heroPost = posts[0];

    bannerCareer = {
      fieldGroupName: "banner",
      key: heroPost.id,
      title: heroPost.title,
      uri: heroPost.uri,
      date: moment(heroPost.date).format("D. MMMM YYYY"),
      content: heroPost.content,
    };
  }

  return (
    <Layout>
      <SEO title={"Magazín"} />
      <Header variant="light" />
      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column" mb={[3, null, 2]}>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              Magazín
            </Heading>
          </Flex>
        </Container>
      </Wrapper>
      {heroPost && <Hero type="magazine" content={bannerCareer} categories={heroPost.categories.nodes} image={heroPost?.featuredImage.node} />}
      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection={["column", null, "row"]} flexWrap={"wrap"} justifyContent={["flex-start", null, "space-between"]} mb={[50, null, null, 110]}>
            {posts && <MagazineBlockBig posts={posts} hasNextPage={hasNextPage} pageNumber={pageNumber} />}
            <Flex width={[1, null, null, "30%", "23%"]} flexDirection={"column"}>
              <Heading as="h3" fontSize={[18, null, 3]} lineHeight={["21px", null, "23px"]} fontWeight={600} color={"headlinesSecondary"} mb={3}>
                Doporučené články
              </Heading>

              <MagazineBlockSmall sidebar={true} flexDirection={["column", null, "row", "column", null]} posts={stickyPosts} />

              {categories && (
                <>
                  <Heading as="h3" fontSize={[18, null, 3]} lineHeight={["21px", null, "23px"]} fontWeight={600} color={"headlinesSecondary"} mt={40} mb={3}>
                    Kategorie
                  </Heading>
                  <Flex alignItems="center" flexWrap="wrap" mb={4}>
                    {categories.map(
                      category =>
                        category.posts.nodes.length !== 0 && (
                          <TagLink
                            key={category.id}
                            href={`${URLS.blog}${category.slug}/`}
                            content={category.name}
                            variant={"light"}
                            count={category.posts.nodes.length ? category.posts.nodes.length : "0"}
                            background={"dark"}
                            mb={10}
                          />
                        )
                    )}
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Magazin;

export const query = graphql`
  query GET_POSTS($ids: [ID]) {
    wp {
      posts(where: { in: $ids }) {
        nodes {
          id
          title
          uri
          slug
          date
          content: excerpt
          categories {
            nodes {
              id
              name
              link
              slug
              uri
            }
          }
          featuredImage {
            node {
              heroImage: sourceUrl(size: MEDIUM_LARGE)
              sourceUrl(size: MEDIUM)
            }
          }
        }
      }
      stickyPosts: posts(where: { onlySticky: true }) {
        nodes {
          id
          title
          uri
          slug
          date
          featuredImage {
            node {
              sourceUrl(size: THUMBNAIL)
            }
          }
        }
      }
      categories(first: 999, after: null) {
        nodes {
          categoryId
          posts(first: 100) {
            nodes {
              id
            }
          }
          name
          id
          slug
        }
      }
    }
  }
`;
